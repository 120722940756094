<template>
  <div class="login_container">
    <vue-particles
      color="#fff"
      :particleOpacity="0.7"
      :particlesNumber="60"
      shapeType="triangle"
      :particleSize="4"
      linesColor="#fff"
      :linesWidth="1"
      :lineLinked="true"
      :lineOpacity="0.4"
      :linesDistance="150"
      :moveSpeed="3"
      :hoverEffect="true"
      hoverMode="grab"
      :clickEffect="true"
      clickMode="push"
      class="lizi"
      style="height: 100%"
    >
    </vue-particles>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  data() {
    return {};
  },
  mounted() {
  },
  methods: {
    
  },
};
</script>

<style lang="scss" scoped>
.login_container {
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  position: absolute;
  width: 100%;
  opacity: 0.4;
}
</style>
