import Vue from 'vue'
import App from './App.vue'
import router from './router'
// axios
import axios from 'axios'
import VueParticles from 'vue-particles'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

const config = require('./assets/http.js')
const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: config.baseUrl,
  timeout: 30000,
  headers: {'Content-Type':'application/json; charset=UTF-8'}
})

  // WEBsocket
  // let ws = new WebSocket("ws://localhost:8060/huizheng/getList?pageUrl=cate-147&pageIndex=0");
      
  // ws.onopen = () => {
  //   console.log('WebSocket连接已打开');
  // }

  // ws.onmessage = (event) => {
  //   console.log(event.data);
  // }

  // ws.onclose = () => {
  //   console.log('WebSocket连接已关闭');
  // }

  // ws.onerror = (err) => {
  //   console.log(err, "报错");
  // }


Vue.use(VueParticles)
Vue.use(ElementUI);

Vue.prototype.$http = axiosIns

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
