<template>
  <div class="vr-box">
    <iframe
      id="inlineFrameExample"
      title="Inline Frame Example"
      width="100%"
      height="100%"
      src="https://www.720yun.com/vr/1f8jkdtvrO5">
    </iframe>
    <div class="back" @click="onBack"></div>
  </div>
</template>

<script>
export default {
  methods: {
    onBack() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.vr-box {
  width: 100vw;
  height: 100vh;
  .back {
      position: fixed;
      top: 50vh;
      left: 20px;
      width: 80px;
      height: 80px;
      cursor: pointer;
      transform: translateY(-40px);
      background: url('../assets/back.png') no-repeat;
      background-size: 100% 100%;
      z-index: 1000;
    }
}
</style>