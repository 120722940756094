<template>
  <div class="index">
    <div class="back" @click="onBack"></div>
    <div class="title">产品介绍</div>
    <div class="contian">
      <div class="swiper-box">
        <div class="valves"></div>
        <div class="swiper">
          <div class="toast" >
            <div class="toast-text" :style="{height: isOpen ? '74vh' : '0vh'}" v-html="typeText"></div>
            <div class="switch" @click="isOpen = !isOpen">{{ isOpen ? '点击关闭' : '点击展开介绍' }}</div>
          </div>
          <div class="border-box">
            <el-carousel :height="'70vh'" :interval="6000" arrow="always" indicator-position="outside" trigger="click">
              <el-carousel-item v-for="(item, index) in productList" :key="index">
                <el-image :src="`${item.imgUrl}`" :preview-src-list="[`${item.imgUrl}`]" :alt="item.name"></el-image>
                <div class="item-name">{{item.name}}</div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <div class="intro">
          <div></div>
        </div>
      </div>
      <div class="cate-box">
        <div :class="`cate ${item.class}`" :id="`cate-${index}`" @click="getHtml(item.url, index)" v-for="(item, index) in pageList" :key="index">{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      productList: [],
      typeText: '',
      isOpen: false,
      pageList: [
        {
          url: 'cate-147',
          name: '气动调节阀',
          class: 'out',
          imgList: [
            {
              detailUrl: "/Products/detail-1472108.html",
              imgUrl: "https://hzsjvideo.wzzskj.cn/%e6%b1%87%e6%ad%a3%e9%98%80%e9%97%a8/%e6%b0%94%e5%8a%a8%e8%b0%83%e8%8a%82%e9%98%80/气动调节阀2.jpg",
              name: "气动调节阀",
            },
            {
              detailUrl: "/Products/detail-1472108.html",
              imgUrl: "https://hzsjvideo.wzzskj.cn/%e6%b1%87%e6%ad%a3%e9%98%80%e9%97%a8/%e6%b0%94%e5%8a%a8%e8%b0%83%e8%8a%82%e9%98%80/气动单座调节阀.jpg",
              name: "气动单座调节阀",
            },
            {
              detailUrl: "/Products/detail-1472108.html",
              imgUrl: "https://hzsjvideo.wzzskj.cn/%e6%b1%87%e6%ad%a3%e9%98%80%e9%97%a8/%e6%b0%94%e5%8a%a8%e8%b0%83%e8%8a%82%e9%98%80/气动调节阀3.jpg",
              name: "气动调节阀",
            },
            {
              detailUrl: "/Products/detail-1472108.html",
              imgUrl: "https://hzsjvideo.wzzskj.cn/%e6%b1%87%e6%ad%a3%e9%98%80%e9%97%a8/%e6%b0%94%e5%8a%a8%e8%b0%83%e8%8a%82%e9%98%80/气动高压调节阀.jpg",
              name: "气动高压调节阀",
            },
            {
              detailUrl: "/Products/detail-1472108.html",
              imgUrl: "https://hzsjvideo.wzzskj.cn/%e6%b1%87%e6%ad%a3%e9%98%80%e9%97%a8/%e6%b0%94%e5%8a%a8%e8%b0%83%e8%8a%82%e9%98%80/气动套筒调节阀.jpg",
              name: "气动套筒调节阀",
            },
            {
              detailUrl: "/Products/detail-1472108.html",
              imgUrl: "https://hzsjvideo.wzzskj.cn/%e6%b1%87%e6%ad%a3%e9%98%80%e9%97%a8/%e6%b0%94%e5%8a%a8%e8%b0%83%e8%8a%82%e9%98%80/鼠笼式单座调节阀.jpg",
              name: "鼠笼式单座调节阀",
            },
            {
              detailUrl: "/Products/detail-1472108.html",
              imgUrl: "https://hzsjvideo.wzzskj.cn/%E6%B1%87%E6%AD%A3%E9%98%80%E9%97%A8/%E6%B0%94%E5%8A%A8%E8%B0%83%E8%8A%82%E9%98%80/%E6%B0%94%E5%8A%A8%E8%96%84%E8%86%9C%E4%BF%9D%E6%B8%A9%E5%A4%B9%E5%A5%97%E8%B0%83%E8%8A%82%E9%98%80.jpg",
              name: "气动薄膜保温夹套调节阀",
            },
            {
              detailUrl: "/Products/detail-1472108.html",
              imgUrl: "https://hzsjvideo.wzzskj.cn/%e6%b1%87%e6%ad%a3%e9%98%80%e9%97%a8/%e6%b0%94%e5%8a%a8%e8%b0%83%e8%8a%82%e9%98%80/气动衬氟单座调节阀.jpg",
              name: "气动衬氟单座调节阀",
            },
            {
              detailUrl: "/Products/detail-1472108.html",
              imgUrl: "https://hzsjvideo.wzzskj.cn/%e6%b1%87%e6%ad%a3%e9%98%80%e9%97%a8/%e6%b0%94%e5%8a%a8%e8%b0%83%e8%8a%82%e9%98%80/气动衬氟调节阀.jpg",
              name: "气动衬氟调节阀",
            },
            {
              detailUrl: "/Products/detail-1472108.html",
              imgUrl: "https://hzsjvideo.wzzskj.cn/%e6%b1%87%e6%ad%a3%e9%98%80%e9%97%a8/%e6%b0%94%e5%8a%a8%e8%b0%83%e8%8a%82%e9%98%80/气动低温调节阀.jpg",
              name: "气动低温调节阀",
            },
            {
              detailUrl: "/Products/detail-1472108.html",
              imgUrl: "https://hzsjvideo.wzzskj.cn/%e6%b1%87%e6%ad%a3%e9%98%80%e9%97%a8/%e6%b0%94%e5%8a%a8%e8%b0%83%e8%8a%82%e9%98%80/气动角式调节阀.jpg",
              name: "气动角式调节阀",
            },
            {
              detailUrl: "/Products/detail-1472108.html",
              imgUrl: "https://hzsjvideo.wzzskj.cn/%e6%b1%87%e6%ad%a3%e9%98%80%e9%97%a8/%e6%b0%94%e5%8a%a8%e8%b0%83%e8%8a%82%e9%98%80/气动三通调节阀.jpg",
              name: "气动三通调节阀",
            },
            {
              detailUrl: "/Products/detail-1472108.html",
              imgUrl: "https://hzsjvideo.wzzskj.cn/%e6%b1%87%e6%ad%a3%e9%98%80%e9%97%a8/%e6%b0%94%e5%8a%a8%e8%b0%83%e8%8a%82%e9%98%80/气动三通分流合流调节阀.jpg",
              name: "气动三通分流合流调节阀",
            },
            {
              detailUrl: "/Products/detail-1472108.html",
              imgUrl: "https://hzsjvideo.wzzskj.cn/%e6%b1%87%e6%ad%a3%e9%98%80%e9%97%a8/%e6%b0%94%e5%8a%a8%e8%b0%83%e8%8a%82%e9%98%80/气动双座调节阀.jpg",
              name: "气动双座调节阀",
            },
            {
              detailUrl: "/Products/detail-1472108.html",
              imgUrl: "https://hzsjvideo.wzzskj.cn/%e6%b1%87%e6%ad%a3%e9%98%80%e9%97%a8/%e6%b0%94%e5%8a%a8%e8%b0%83%e8%8a%82%e9%98%80/气动套筒调节阀2.jpg",
              name: "气动套筒调节阀2",
            },
          ],
          text: `
          <div class="toast-title">调节阀</div>
          <div class="toast-line"><div class="lable">[规格型号]:</div> <div class="value">ZDLP/ZDLM/ZJHP/ZJHM</div></div>     
          <div class="toast-line"><div class="lable">[驱动类型]:</div> <div class="value">气动、电动、手动</div></div>                                                                                                
          <div class="toast-line"><div class="lable">[口    径]:</div> <div class="value">DN15-DN500mm</div></div>  
          <div class="toast-line"><div class="lable">[产品用途]:</div> <div class="value">通过开关的启闭，达到控制流体流量的目的</div></div>  
          <div class="toast-line"><div class="lable">[公称压力]:</div> <div class="value">PN10、16、25、40、64、100、150、250、420
150LB、300LB、600LB、900LB、1500LB、2500LB</div></div>  
          <div class="toast-line"><div class="lable">[阀体材质]:</div> <div class="value">WCB/CF8/CF3/CF8M/CF3M/特殊材料定制</div></div>  
          <div class="toast-line"><div class="lable">[连接方式]:</div> <div class="value">法兰连接:RF、FM、FF、RJ;
法兰标准：HG/T20592-2009、HG/T20615-2009、ANSI B 16.5、JIS B2201-1984;
对夹式、对焊式</div></div>  
          <div class="toast-line"><div class="lable">[阀座密封面]:</div> <div class="value">柱塞型、平衡笼式、多级降压低噪音、先导式、三通硬密封、软密封、堆焊硬质合金、衬橡胶/氟塑</div></div>  
          <div class="toast-line"><div class="lable">[温度范围]:</div> <div class="value">-196℃~+560℃</div></div>  
          <div class="toast-line"><div class="lable">[产品特点]:</div> <div class="value">控制简单、反应快速、操作稳定，可实现多种流量特性，有多种阀门结构可选择</div></div>  
          <div class="toast-line"><div class="lable">[适用介质]:</div> <div class="value">适用气体、液体、蒸汽等 </div></div>  
          <div class="toast-line"><div class="lable">[应用场景]:</div> <div class="value">广泛应用于电力、化工、冶金、环保、水处理、轻工、建材等工业自动化系统领域。</div></div>  
`
        },
        {
          url: 'cate-144',
          name: '电动调节阀',
          class: 'out',
          imgList: [
            {
              detailUrl: "/Products/detail-1472108.html",
              imgUrl: "https://hzsjvideo.wzzskj.cn/%E6%B1%87%E6%AD%A3%E9%98%80%E9%97%A8/%E7%94%B5%E5%8A%A8%E8%B0%83%E8%8A%82%E9%98%80/电动超高压调节阀.jpg",
              name: "电动超高压调节阀",
            },
            {
              detailUrl: "/Products/detail-1472108.html",
              imgUrl: "https://hzsjvideo.wzzskj.cn/%E6%B1%87%E6%AD%A3%E9%98%80%E9%97%A8/%E7%94%B5%E5%8A%A8%E8%B0%83%E8%8A%82%E9%98%80/电动锻打高压调节阀.jpg",
              name: "电动锻打高压调节阀",
            },
            {
              detailUrl: "/Products/detail-1472108.html",
              imgUrl: "https://hzsjvideo.wzzskj.cn/%E6%B1%87%E6%AD%A3%E9%98%80%E9%97%A8/%E7%94%B5%E5%8A%A8%E8%B0%83%E8%8A%82%E9%98%80/电动三通调节阀.jpg",
              name: "电动三通调节阀",
            },
            {
              detailUrl: "/Products/detail-1472108.html",
              imgUrl: "https://hzsjvideo.wzzskj.cn/%E6%B1%87%E6%AD%A3%E9%98%80%E9%97%A8/%E7%94%B5%E5%8A%A8%E8%B0%83%E8%8A%82%E9%98%80/电动三通分流合流调节阀.jpg",
              name: "电动三通分流合流调节阀",
            },
            {
              detailUrl: "/Products/detail-1472108.html",
              imgUrl: "https://hzsjvideo.wzzskj.cn/%E6%B1%87%E6%AD%A3%E9%98%80%E9%97%A8/%E7%94%B5%E5%8A%A8%E8%B0%83%E8%8A%82%E9%98%80/电动套筒调节阀.jpg",
              name: "电动套筒调节阀",
            },
            {
              detailUrl: "/Products/detail-1472108.html",
              imgUrl: "https://hzsjvideo.wzzskj.cn/%E6%B1%87%E6%AD%A3%E9%98%80%E9%97%A8/%E7%94%B5%E5%8A%A8%E8%B0%83%E8%8A%82%E9%98%80/电动温控调节阀.jpg",
              name: "电动温控调节阀",
            },
            {
              detailUrl: "/Products/detail-1472108.html",
              imgUrl: "https://hzsjvideo.wzzskj.cn/%E6%B1%87%E6%AD%A3%E9%98%80%E9%97%A8/%E7%94%B5%E5%8A%A8%E8%B0%83%E8%8A%82%E9%98%80/电动温控流量调节阀.jpg",
              name: "电动温控流量调节阀",
            },
          ],
          text: `
          <div class="toast-title">调节阀</div>
          <div class="toast-line"><div class="lable">[规格型号]:</div> <div class="value">ZDLP/ZDLM/ZJHP/ZJHM</div></div>     
          <div class="toast-line"><div class="lable">[驱动类型]:</div> <div class="value">气动、电动、手动</div></div>                                                                                                
          <div class="toast-line"><div class="lable">[口    径]:</div> <div class="value">DN15-DN500mm</div></div>  
          <div class="toast-line"><div class="lable">[产品用途]:</div> <div class="value">通过开关的启闭，达到控制流体流量的目的</div></div>  
          <div class="toast-line"><div class="lable">[公称压力]:</div> <div class="value">PN10、16、25、40、64、100、150、250、420
150LB、300LB、600LB、900LB、1500LB、2500LB</div></div>  
          <div class="toast-line"><div class="lable">[阀体材质]:</div> <div class="value">WCB/CF8/CF3/CF8M/CF3M/特殊材料定制</div></div>  
          <div class="toast-line"><div class="lable">[连接方式]:</div> <div class="value">法兰连接:RF、FM、FF、RJ;
法兰标准：HG/T20592-2009、HG/T20615-2009、ANSI B 16.5、JIS B2201-1984;
对夹式、对焊式</div></div>  
          <div class="toast-line"><div class="lable">[阀座密封面]:</div> <div class="value">柱塞型、平衡笼式、多级降压低噪音、先导式、三通硬密封、软密封、堆焊硬质合金、衬橡胶/氟塑</div></div>  
          <div class="toast-line"><div class="lable">[温度范围]:</div> <div class="value">-196℃~+560℃</div></div>  
          <div class="toast-line"><div class="lable">[产品特点]:</div> <div class="value">控制简单、反应快速、操作稳定，可实现多种流量特性，有多种阀门结构可选择</div></div>  
          <div class="toast-line"><div class="lable">[适用介质]:</div> <div class="value">适用气体、液体、蒸汽等 </div></div>  
          <div class="toast-line"><div class="lable">[应用场景]:</div> <div class="value">广泛应用于电力、化工、冶金、环保、水处理、轻工、建材等工业自动化系统领域。</div></div>  
`
        },
        {
          url: 'cate-142',
          name: '球阀',
          imgList: [
            {
              detailUrl: "/Products/detail-1472108.html",
              imgUrl: "https://hzsjvideo.wzzskj.cn/%E6%B1%87%E6%AD%A3%E9%98%80%E9%97%A8/球阀/电动精小型球阀.jpg",
              name: "电动精小型球阀",
            },
            {
              detailUrl: "/Products/detail-1472108.html",
              imgUrl: "https://hzsjvideo.wzzskj.cn/%E6%B1%87%E6%AD%A3%E9%98%80%E9%97%A8/球阀/气动V型调节球阀.jpg",
              name: "气动V型调节球阀",
            },
            {
              detailUrl: "/Products/detail-1472108.html",
              imgUrl: "https://hzsjvideo.wzzskj.cn/%E6%B1%87%E6%AD%A3%E9%98%80%E9%97%A8/球阀/气动衬氟球阀.jpg",
              name: "气动衬氟球阀",
            },
            {
              detailUrl: "/Products/detail-1472108.html",
              imgUrl: "https://hzsjvideo.wzzskj.cn/%E6%B1%87%E6%AD%A3%E9%98%80%E9%97%A8/球阀/气动锻钢高压球阀.jpg",
              name: "气动锻钢高压球阀",
            },
            {
              detailUrl: "/Products/detail-1472108.html",
              imgUrl: "https://hzsjvideo.wzzskj.cn/%E6%B1%87%E6%AD%A3%E9%98%80%E9%97%A8/球阀/气动对夹式V型球阀.jpg",
              name: "气动对夹式V型球阀",
            },
            {
              detailUrl: "/Products/detail-1472108.html",
              imgUrl: "https://hzsjvideo.wzzskj.cn/%E6%B1%87%E6%AD%A3%E9%98%80%E9%97%A8/球阀/气动防爆球阀.jpg",
              name: "气动防爆球阀",
            },
            {
              detailUrl: "/Products/detail-1472108.html",
              imgUrl: "https://hzsjvideo.wzzskj.cn/%E6%B1%87%E6%AD%A3%E9%98%80%E9%97%A8/球阀/气动切断球阀.jpg",
              name: "气动切断球阀",
            },
            {
              detailUrl: "/Products/detail-1472108.html",
              imgUrl: "https://hzsjvideo.wzzskj.cn/%E6%B1%87%E6%AD%A3%E9%98%80%E9%97%A8/球阀/气动软密封O型球阀.jpg",
              name: "气动软密封O型球阀",
            },
            {
              detailUrl: "/Products/detail-1472108.html",
              imgUrl: "https://hzsjvideo.wzzskj.cn/%E6%B1%87%E6%AD%A3%E9%98%80%E9%97%A8/球阀/气动三通球阀.jpg",
              name: "气动三通球阀",
            },
            {
              detailUrl: "/Products/detail-1472108.html",
              imgUrl: "https://hzsjvideo.wzzskj.cn/%E6%B1%87%E6%AD%A3%E9%98%80%E9%97%A8/球阀/气动陶瓷球阀.jpg",
              name: "气动陶瓷球阀",
            },
            {
              detailUrl: "/Products/detail-1472108.html",
              imgUrl: "https://hzsjvideo.wzzskj.cn/%E6%B1%87%E6%AD%A3%E9%98%80%E9%97%A8/球阀/气动硬密封O型切断球阀.jpg",
              name: "气动硬密封O型切断球阀",
            },
            {
              detailUrl: "/Products/detail-1472108.html",
              imgUrl: "https://hzsjvideo.wzzskj.cn/%E6%B1%87%E6%AD%A3%E9%98%80%E9%97%A8/球阀/气动硬密封球阀.jpg",
              name: "气动硬密封球阀",
            },
          ],
          class: 'out',
          text: `
          <div class="toast-title">球阀</div>
          <div class="toast-line"><div class="lable">[规格型号]: </div> <div class="value">Q41/Q47 /Q641</div></div>  
          <div class="toast-line"><div class="lable">[驱动类型]:</div> <div class="value">气动、电动、手动    </div></div>  
          <div class="toast-line"><div class="lable">[产品用途]:</div> <div class="value">通过开关的启闭，达到控制流体切断的目的</div></div>  
          <div class="toast-line"><div class="lable">[公称压力]:</div> <div class="value">PN10、16、25、40、63、100、150LB、300LB、600LB  </div></div>           
          <div class="toast-line"><div class="lable">[口    径]:</div> <div class="value">DN15-DN600mm</div></div>  
          <div class="toast-line"><div class="lable">[阀体材质]:</div> <div class="value">WCB/CF8/CF3/CF8M/CF3M/特殊材料定制</div></div>  
          <div class="toast-line"><div class="lable">[连接方式]:</div> <div class="value">法兰连接:RF、FM、FF、RJ;
法兰标准：HG/T20592-2009、HG/T20615-2009、ANSI B 16.5、JIS B2201-1984;
对夹式、丝扣连接</div></div>  
          <div class="toast-line"><div class="lable">[阀座密封面]:</div> <div class="value"> O型球、V型球、多通道、衬橡胶/氟塑
浮动球、固定球
硬密封、软密封、堆焊硬质合金</div></div>  
          <div class="toast-line"><div class="lable">[温度范围]:</div> <div class="value">-40℃~+425℃</div></div>  
          <div class="toast-line"><div class="lable">[产品特点]:</div> <div class="value">密封性好、启闭灵活、使用寿命长、流体阻力小、结构简单、体积小、重量轻、操作方便、开闭迅速、维修方便。</div></div>  
          <div class="toast-line"><div class="lable">[适用介质]:</div> <div class="value">适用气体、液体、蒸汽拉含纤维、微小固体颗粒物等的介质</div></div>  
          <div class="toast-line"><div class="lable">[应用场景]:</div> <div class="value">广泛的应用在石油炼制、长输管线、化工、造纸、制药、水利、电力、市政、钢铁等行业，在国民经济中占有举足轻重的地位。</div></div>    
`
        },
        {
          url: 'cate-158',
          name: '偏心旋转阀',
          class: 'out',
          imgList: [
            {
              detailUrl: "/Products/detail-1472108.html",
              imgUrl: "https://hzsjvideo.wzzskj.cn/%E6%B1%87%E6%AD%A3%E9%98%80%E9%97%A8/偏心旋转阀/偏心旋转阀法兰式.jpg",
              name: "偏心旋转阀法兰式",
            },
            {
              detailUrl: "/Products/detail-1472108.html",
              imgUrl: "https://hzsjvideo.wzzskj.cn/%E6%B1%87%E6%AD%A3%E9%98%80%E9%97%A8/偏心旋转阀/气动偏心旋转阀对夹式.jpg",
              name: "气动偏心旋转阀对夹式",
            },
          ],
          text: `
          <div class="toast-title">偏心旋转阀</div>
          <div class="toast-line"><div class="lable">[规格型号]:</div> <div class="value"> RB10</div></div>  
          <div class="toast-line"><div class="lable">[驱动类型]:</div> <div class="value">气动、电动、手动    </div></div>  
          <div class="toast-line"><div class="lable">[口    径]:</div> <div class="value"> DN25-DN500mm</div></div>  
          <div class="toast-line"><div class="lable">[产品用途]:</div> <div class="value">流量调节及切断</div></div>  
          <div class="toast-line"><div class="lable">[公称压力]:</div> <div class="value">PN10、16、25、40、63、100、150
          150LB、300LB、600LB、900LB</div></div>  
          <div class="toast-line"><div class="lable">[阀体材质]:</div> <div class="value">WCB/CF8/CF3/CF8M/CF3M/特殊材料定制</div></div>  
          <div class="toast-line"><div class="lable">[连接方式]:</div> <div class="value">法兰连接:RF、FM、RJ;
法兰标准：HG/T20592-2009、HG/T20615-2009、ANSI B 16.5、JIS B2201-1984;
对夹式;</div></div>  
          <div class="toast-line"><div class="lable">[阀座密封面]:</div> <div class="value">偏心球面密封
硬密封、软密封、堆焊硬质合金</div></div>  
          <div class="toast-line"><div class="lable">[温度范围]:</div> <div class="value">-40℃~+350℃</div></div>  
          <div class="toast-line"><div class="lable">[产品特点]:</div> <div class="value">阀芯的回转中心不与旋转轴同心，可减少阀座磨损，延长使用寿命，密封性好、启闭灵活、兼顾调节和切断</div></div>  
          <div class="toast-line"><div class="lable">[适用介质]:</div> <div class="value">调节范围大、严密封场所
结晶、结痂不干净介质场所。</div></div>  
          <div class="toast-line"><div class="lable">[应用场景]:</div> <div class="value">广泛应用于石油化工、煤化工、造纸、制药、电力、供水、供暖、热力热网工程等领域，
偏心旋转阀是一种结构新颖的偏心调节球阀。：阀芯后部设有一个导流翼，有利于流体稳定流动，具有优良的稳定性。其次，还有流量大，可调范围广的优点。</div></div>  
`
        },
        {
          url: 'cate-143',
          name: '自力式调节阀',
          class: 'out',
          imgList: [
            {
              detailUrl: "/Products/detail-1472108.html",
              imgUrl: "https://hzsjvideo.wzzskj.cn/%E6%B1%87%E6%AD%A3%E9%98%80%E9%97%A8/自力式/ZZYW自力式差(微)压调节阀.jpg",
              name: "ZZYW自力式差(微)压调节阀",
            },
            {
              detailUrl: "/Products/detail-1472108.html",
              imgUrl: "https://hzsjvideo.wzzskj.cn/%E6%B1%87%E6%AD%A3%E9%98%80%E9%97%A8/自力式/自力式调节阀.jpg",
              name: "自力式调节阀",
            },
            {
              detailUrl: "/Products/detail-1472108.html",
              imgUrl: "https://hzsjvideo.wzzskj.cn/%E6%B1%87%E6%AD%A3%E9%98%80%E9%97%A8/自力式/自力式调节压差.jpg",
              name: "自力式调节压差",
            },
            {
              detailUrl: "/Products/detail-1472108.html",
              imgUrl: "https://hzsjvideo.wzzskj.cn/%E6%B1%87%E6%AD%A3%E9%98%80%E9%97%A8/自力式/自力式供氮阀.jpg",
              name: "自力式供氮阀",
            },
          ],
          text: `
          <div class="toast-title">自力式阀门</div>
          <div class="toast-line"><div class="lable">[规格型号]:</div> <div class="value"> ZZY/ZZVP-16B/ZZCP-16 /ZZYP-16-2/ZZWPE /ZZYVP-16B/ </div></div>  
          <div class="toast-line"><div class="lable">[驱动类型]:</div> <div class="value">阀内介质自身的压力、温度驱动</div></div>  
          <div class="toast-line"><div class="lable">[口    径]:</div> <div class="value">DN15-300mm</div></div>  
          <div class="toast-line"><div class="lable">[产品用途]:</div> <div class="value">依靠介质自身压力变化达到自动调节和稳定压力、恒温的目的。</div></div>  
          <div class="toast-line"><div class="lable">[工作压力]:</div> <div class="value">PN10、16、25、40、64、100
150LB、300LB、600LB</div></div>  
          <div class="toast-line"><div class="lable">[阀体材质]:</div> <div class="value">WCB/CF8/CF3/CF8M/CF3M/特殊材料定制</div></div>  
          <div class="toast-line"><div class="lable">[连接方式]:</div> <div class="value">法兰连接：RF、FF、M、FM
法兰标准：HG/T20592-2009、HG/T20615-2009、ANSI B 16.5、JIS B2201-1984;
焊接式；</div></div>  
          <div class="toast-line"><div class="lable">[阀座密封面]:</div> <div class="value">硬密封、软密封</div></div>  
          <div class="toast-line"><div class="lable">[温度范围]:</div> <div class="value">-100℃~+400℃</div></div>  
          <div class="toast-line"><div class="lable">[产品特点]:</div> <div class="value">通过控制流量，保设定值点不变，动作灵敏、波动力小、密封性好</div></div>  
          <div class="toast-line"><div class="lable">[适用介质]:</div> <div class="value">适用于气体、液体、蒸汽、非腐蚀性气体及低粘度液体的介质。</div></div>  
          <div class="toast-line"><div class="lable">[应用场景]：</div> <div class="value">广泛应用于石油、化工、冶金、轻工等工业部门和城市供热供应系统。</div></div>  
`
        },
        {
          url: 'cate-145',
          name: '滑板阀',
          class: 'out',
          imgList: [
            {
              detailUrl: "/Products/detail-1472108.html",
              imgUrl: "https://hzsjvideo.wzzskj.cn/%E6%B1%87%E6%AD%A3%E9%98%80%E9%97%A8/滑板阀/滑板式调节切断阀.jpg",
              name: "滑板式调节切断阀",
            },
          ],
          text: `
          <div class="toast-title">滑板阀</div>
          <div class="toast-line"><div class="lable">[规格型号]:</div> <div class="value">HB10/HB20/HB30</div></div>  
          <div class="toast-line"><div class="lable">[驱动类型]:</div> <div class="value">气动、电动</div></div>  
          <div class="toast-line"><div class="lable">[口    径]:</div> <div class="value"> DN10-DN500mm/特殊大口径</div></div>  
          <div class="toast-line"><div class="lable">[产品用途]:</div> <div class="value">流量调节及快速切断</div></div>  
          <div class="toast-line"><div class="lable">[公称压力]:</div> <div class="value">PN10、16、25、40、64、100、150、250、420
          150LB、300LB、600LB、900LB、1500LB、2500LB</div></div>  
          <div class="toast-line"><div class="lable">[阀体材质]:</div> <div class="value">WCB/CF8/CF3/CF8M/CF3M/特殊材料定制</div></div>  
          <div class="toast-line"><div class="lable">[连接方式]:</div> <div class="value">法兰连接：RF、FM、RJ;
法兰标准：HG/T20592-2009、HG/T20615-2009、ANSI B 16.5;
焊接式；</div></div>  
          <div class="toast-line"><div class="lable">[阀座密封面]:</div> <div class="value">滑板式平面硬密封（镜面级）、密封面硬化</div></div>  
          <div class="toast-line"><div class="lable">[温度范围]:</div> <div class="value">-40℃~+425℃</div></div>  
          <div class="toast-line"><div class="lable">[产品特点]:</div> <div class="value"> 流道直通，流态稳定，易控制，调节范围大
陷藏式密封面防冲刷气蚀
镜面级密封面，保证严密封</div></div>  
          <div class="toast-line"><div class="lable">[适用介质]:</div> <div class="value">适用高精度调节、严密封场所
适用双相（气+固体/液+固体）高压介质</div></div>  
          <div class="toast-line"><div class="lable">[应用场景]：</div> <div class="value">粉料输送、锁渣/排渣、防气蚀等苛刻工况</div></div>  
`
        },
        {
          url: 'cate-146',
          name: '蝶阀',
          class: 'out',
          imgList: [
            {
              detailUrl: "/Products/detail-1472108.html",
              imgUrl: "https://hzsjvideo.wzzskj.cn/%E6%B1%87%E6%AD%A3%E9%98%80%E9%97%A8/蝶阀/电动高性能蝶阀.jpg",
              name: "电动高性能蝶阀",
            },
            {
              detailUrl: "/Products/detail-1472108.html",
              imgUrl: "https://hzsjvideo.wzzskj.cn/%E6%B1%87%E6%AD%A3%E9%98%80%E9%97%A8/蝶阀/放空蝶阀 D641W-16P.jpg",
              name: "放空蝶阀 D641W-16P",
            },
            {
              detailUrl: "/Products/detail-1472108.html",
              imgUrl: "https://hzsjvideo.wzzskj.cn/%E6%B1%87%E6%AD%A3%E9%98%80%E9%97%A8/蝶阀/气动蝶阀.jpg",
              name: "气动蝶阀",
            },
            {
              detailUrl: "/Products/detail-1472108.html",
              imgUrl: "https://hzsjvideo.wzzskj.cn/%E6%B1%87%E6%AD%A3%E9%98%80%E9%97%A8/蝶阀/气动蝶阀2.jpg",
              name: "气动蝶阀2",
            },
            {
              detailUrl: "/Products/detail-1472108.html",
              imgUrl: "https://hzsjvideo.wzzskj.cn/%E6%B1%87%E6%AD%A3%E9%98%80%E9%97%A8/蝶阀/气动开关蝶阀对夹 D673w.jpg",
              name: "气动开关蝶阀对夹 D673w",
            },
          ],
          text: `
          <div class="toast-title">蝶阀</div>
          <div class="toast-line"><div class="lable">[规格型号]:</div> <div class="value">WL10-20/WB20-30/WD10/WF10/WM10</div></div>  
          <div class="toast-line"><div class="lable">[驱动类型]:</div> <div class="value">电动、气动、手动    </div></div>  
          <div class="toast-line"><div class="lable">[口    径]:</div> <div class="value">DN80~2000mm</div></div>  
          <div class="toast-line"><div class="lable">[产品用途]:</div> <div class="value"> 通过开关的启闭，达到控制流体流量或切断的目的</div></div>  
          <div class="toast-line"><div class="lable">[公称压力]:</div> <div class="value"> PN2.5、6、10、16、25、40、64、100
          150LB、300LB、600LB</div></div>  
          <div class="toast-line"><div class="lable">[阀体材质]:</div> <div class="value">WCB/CF8/CF3/CF8M/CF3M/特殊材料定制</div></div>  
          <div class="toast-line"><div class="lable">[连接方式]:</div> <div class="value">对夹式；
法兰连接：RF
法兰标准：HG/T20592-2009、HG/T20615-2009、ANSI B 16.5;</div></div>  
          <div class="toast-line"><div class="lable">[阀座密封面]:</div> <div class="value"> 中线型、偏心型、低温型
硬密封、软密封、堆焊硬质合金、衬橡胶/氟塑</div></div>  
          <div class="toast-line"><div class="lable">[温度范围]:</div> <div class="value">-40℃~+425℃</div></div>  
          <div class="toast-line"><div class="lable">[产品特点]:</div> <div class="value">流通能力大、结构简单、体积小、重量轻、材料耗用省，安装尺寸小，开关迅速、90°往复回转，驱动力矩小等特点，用于截断、接通、调节管路中的介质，具有良好的流体控制特性和关闭密封（偏心型）性能。</div></div>  
          <div class="toast-line"><div class="lable">[适用介质]:</div> <div class="value">通常适用于控制大流量、低压差流体，阀流道具有自洁作用，适用于料浆流体。</div></div>  
          <div class="toast-line"><div class="lable">[应用场景]:</div> <div class="value">广泛用于冶金、轻工、城市供热、供气、供水、石油、石化、化工、冶金、电力等领域</div></div>  
`
        },
        {
          url: 'cate-159',
          name: '闸阀/执行机构等',
          class: 'out',
          imgList: [
            {
              detailUrl: "/Products/detail-1472108.html",
              imgUrl: "http://www.hztjv.com/upload/product/2214559493.jpg",
              name: "气动执行器 带顶装气动薄膜执行机构",
            },
            {
              detailUrl: "/Products/detail-1472108.html",
              imgUrl: "http://www.hztjv.com/upload/product/2584413362.jpg",
              name: "气动薄膜执行器 整体常开气动薄膜头",
            },
            {
              detailUrl: "/Products/detail-1472108.html",
              imgUrl: "https://hzsjvideo.wzzskj.cn/%e6%b1%87%e6%ad%a3%e9%98%80%e9%97%a8/%e6%b0%94%e5%8a%a8%e8%b0%83%e8%8a%82%e9%98%80/气动平行双闸板闸阀.jpg",
              name: "气动平行双闸板闸阀",
            },
          ],
          text: `
          <div class="toast-title">执行机构/配件</div>`
        }
      ]
    }
  },
  methods: {
    async getHtml(url, key) {
      // const res = await this.$http.get(`getList?pageUrl=${url}&pageIndex=0`)
      // console.log(res.data);
      // this.productList = res.data;
      this.productList = [...this.pageList[key].imgList]
      this.onMove(key)
    },
    onMove(key) {
      this.pageList.map((item, index) => {
        if(key === index) {
          item.class = 'on'
        } else {
          item.class = 'out'
        }
      })
      this.typeText = this.pageList[key].text
      this.pageList = [...this.pageList]
    },
    onBack() {
      this.$router.go(-1)
    }
  },
  mounted() {
    this.getHtml('cate-147', 0);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  ::v-deep {
    .el-carousel__button {
      width: 100%;
    }
    .el-carousel__indicator--horizontal {
      flex: 1;
      width: 100%;
      max-width: 30px;
    }
    .el-carousel__indicators--outside {
      max-width: 100%;
      display: flex;
      justify-content: center;
      // overflow: auto;
    }
  }
  
  .index{
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    .title {
      width: 100%;
      font-size: 40px;
      text-align: center;
      padding: 20px 0;
      color: #fff;
      letter-spacing: 0.5em;
    }
    .contian {
      display: flex;
      width: 100%;
      height: 90vh;
      padding-bottom: 20px;
    }
    .back {
      position: fixed;
      top: 20px;
      left: 20px;
      width: 80px;
      height: 80px;
      cursor: pointer;
      background: url('../assets/back.png') no-repeat;
      background-size: 100% 100%;
      z-index: 1000;
    }
    .swiper-box {
      position: relative;
      display: flex;
      width: 70%;
      height: 100%;
      justify-content: center;
      align-items: center;
      .valves {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 76vh;
        background: url('../assets/valves.png') no-repeat;
        background-size: contain;
        background-position-x: 6vw;
      }
      .swiper {
        display: inline-block;
        width: 70%;
        position: relative;
        border-image: url('../assets/border.png') 40 40 round;
        border-top: 30px solid;
        border-right: 30px solid;
        border-bottom: 30px solid;
        border-left: 30px solid;
        overflow: hidden;
        .toast {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          background: #000000cc;
          z-index: 1000;
          .toast-text {
            margin: 0 auto;
            margin-top: 40px;
            width: 94%;
            text-indent: 2em;
            text-align: left;
            line-height: 20px;
            font-size: 16px;
            transition: all linear 0.2s;
            overflow: auto;
            white-space: pre-wrap;
            color: #fff;
            box-shadow: inset 0px -10px 10px 10px rgba(255, 255, 255, 0.5);
            ::v-deep .toast-title {
              font-size: 30px;
              line-height: 0px;
            }
            ::v-deep .toast-line {
              display: flex;
              width: 100%;
              justify-content: flex-start;
              .lable {
                width: 30%;
              }
              .value {
                width: 70%;
                text-indent: 0em !important;
              }
            }
          }
          .switch {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fffffff0;
            cursor: pointer;
            line-height: 40px;
          }
        }
        .border-box {
          width: 100%;
          height: 100%;
          background: #1296DBa0;
          padding: 20px 0;
        // background-size: 80% 80%;
          .item-name {
            position: absolute;
            bottom: 0;
            left: 8%;
            right: 8%;
            top: 90%;
            background: #000000a0;
            line-height: 5vh;
            text-align: center;
            color: #fff;
            font-size: 20px;
            font-weight: 600;
          }
        }
        
      }
    }
    .cate-box {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 30%;
      height: 100%;
      .cate {
        -webkit-user-select: none; /* Chrome/Safari/Opera */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently not supported by any browser */
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: 90%;
        height: 10%;
        border-radius: 50px;
        text-align: center;
        font-size: 30px;
        color: #fff;
	      background:linear-gradient(to right,#52bae4 0%,#0a50f5 100%);
        transition: background-position 0.5s;
        background-size: 170% 100%;
      }
      .cate:hover {
	      // background: #599aad;
        background-size: 200% 100%;
        background-position: -100% 0;
      }
      .on {
	      background: #0a50f5;
      }
    }
  }
</style>
