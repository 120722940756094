<template>
  <div class="home">
    <particles></particles>
    <div class="logo"></div>
    <div class="router-btn">
      <div class="btn" @click="goPage()">产品展示</div>
      <div class="btn" @click="goVR()">VR展示</div>
    </div>
  </div>
</template>

<script>
import Particles from '@/components/Particles'
export default {
  components: {Particles},
  methods: {
    goPage() {
      this.$router.push("/index")
    },
    goVR() {
      this.$router.push("/vr")
    }
  }
}
</script>

<style lang="scss" scoped>
  ::-webkit-scrollbar {
    display: none;
  }
  .home {
    width: 100%;
    height: 100%;
    position: relative;
    .logo {
      position: fixed;
      top: 10vh;
      left: 35vw;
      width: 30vw;
      height: 30vh;
      background: url("../assets/huizhenglogo.png") no-repeat;
      background-size: 100%;
    }
    .router-btn {
      position: fixed;
      bottom: 10vh;
      left: 0;
      width: 100%;
      height: 20vh;
      display: flex;
      justify-content: center;
      align-items: center;
      .btn {
        -webkit-user-select: none; /* Chrome/Safari/Opera */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently not supported by any browser */
        margin: 0;
        padding: 0;
        margin-left: 5vw;
        margin-right: 5vw;
        cursor: pointer;
        width: 20vw;
        height: 50%;
        background: #2C83EC;
        color: #fff;
        border-radius: 40px;
        font-size: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
</style>